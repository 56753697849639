import React from 'react';
import {View, Image, ImageBackground} from 'react-native';
import theme from '@style';
import {useSettingWindowDimensions} from '@/store/useSettingStore';
import Text from '@/components/basic/text';
import Button from '@/components/basic/button';
import {downloadApk} from '@/utils';

const HomeFooter = () => {
  const {screenWidth, calculateItemWidth} = useSettingWindowDimensions();
  const itemWidth = screenWidth - 12 * 2;
  const mdIconWH = calculateItemWidth(112);

  return (
    <View
      style={[theme.padding.lrl, theme.flex.centerByCol, theme.position.rel]}>
      <Image
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {
            marginTop: 30,
            height: calculateItemWidth(126),
            width: itemWidth,
            zIndex: 3,
          },
        ]}
        resizeMode="stretch"
        source={require('@assets/imgs/home/footer-header-image.webp')}
      />
      <ImageBackground
        source={require('@assets/imgs/home/footer-bg-image.webp')}
        resizeMode="stretch"
        style={[
          theme.flex.centerByCol,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            zIndex: 2,
            width: itemWidth,
            height: calculateItemWidth(295),
            marginTop: calculateItemWidth(-20),
          },
        ]}>
        <Image
          style={[
            // eslint-disable-next-line react-native/no-inline-styles
            {
              height: mdIconWH,
              width: mdIconWH,
              marginTop: calculateItemWidth(30),
              borderRadius: 10,
              borderColor: '#7230FF',
              borderWidth: 1,
            },
          ]}
          source={require('@assets/logos/logo.png')}
        />
        <>
          <Text style={[theme.margin.topxxl]} blod white fontSize={24}>
            Let's start!
          </Text>
          <Text
            color={theme.fontColor.white60}
            style={[theme.margin.topxxs]}
            fontSize={14}>
            Start your adventure...
          </Text>
        </>
        <View style={[theme.margin.topxxl]}>
          <Button
            title="DOWNLOAD"
            type="linear-primary"
            radius={30}
            onPress={downloadApk}
          />
        </View>
      </ImageBackground>
    </View>
  );
};

export default HomeFooter;
